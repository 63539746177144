<template>
  <Container>
    <v-card-text v-if="!success">
      <v-form @submit.prevent="reset">
        <v-text-field
          v-model="password"
          :error-messages="passwordError"
          :hide-details="passwordError===''"
          outlined
          :type="isPasswordVisible ? 'text' : 'password'"
          label="Mật khẩu mới"
          :append-icon="isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
          @click:append="isPasswordVisible = !isPasswordVisible"
        ></v-text-field>

        <v-text-field
          v-model="cPassword"
          :error-messages="passwordError"
          :hide-details="passwordError===''"
          outlined
          :type="isCPasswordVisible ? 'text' : 'password'"
          label="Xác nhận mật khẩu mới"
          :append-icon="isCPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
          class="mt-3"
          @click:append="isCPasswordVisible = !isCPasswordVisible"
        ></v-text-field>

        <v-btn
          block
          :disabled="loading"
          color="primary"
          class="mt-6"
          type="submit"
        >
          <v-progress-circular
            v-if="loading"
            :size="14"
            :width="2"
            indeterminate
          />
          <span v-else>Lưu mật khẩu mới</span>
        </v-btn>
      </v-form>
    </v-card-text>
    <v-card-text v-else>
      <p class="mb-2">
        <v-icon
          color="success"
          left
        >
          mdi-check-circle-outline
        </v-icon>
        <span class="text-subtitle-2">Cập nhật mật khẩu thành công</span>
      </p>

      <v-btn
        href="/login"
        block
        color="primary"
        class="mt-6"
      >
        <span>Đăng nhập</span>
      </v-btn>
    </v-card-text>
  </Container>
</template>

<script>

import { ref } from '@vue/composition-api'
import Container from '../components/Container.vue'

export default {
  components: {
    Container,
  },

  setup() {
    const isPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const password = ref('')

    return {
      isPasswordVisible,
      isCPasswordVisible,
      password,
    }
  },

  data() {
    return {
      errors: {},
      message: '',
      loading: false,
      success: false,
      password: '',
      cPassword: '',
    }
  },

  computed: {
    passwordError() {
      return this.errors.password || this.errors.auth || ''
    },
    cPasswordError() {
      return this.errors.confirm_password || ''
    },
    token() {
      return this.$route.params.token
    },
    email() {
      return this.$route.params.email
    },
  },

  methods: {
    reset() {
      this.errors = {}

      if (this.password !== this.cPassword) {
        this.errors = {
          password: 'Mật khẩu không trùng khớp',
          confirm_password: 'Mật khẩu không trùng khớp',
        }

        return
      }
      this.loading = true

      const payload = {
        token: this.token,
        email: this.email,
        password: this.password,
      }
      this.$http.post('/api/auth/user/reset-password', payload)
        .then(() => {
          this.success = true
        })
        .catch(err => {
          if (err.response.data.errors) {
            this.errors = err.response.data.errors
          } else if (err.response.data.message) {
            this.message = err.response.data.message
          } else {
            this.message = 'Something is wrong!'
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
